import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  
  const doLogout = () => {
    localStorage.clear()
    logout({ returnTo: window.location.origin })
  }

  return (
    <a href="#logout" onClick={doLogout}>
      Log Out
    </a>
  );
};

export default LogoutButton;