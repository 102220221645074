import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'
import { Link } from 'react-router-dom'

export default function ConfigPageList() {
    const [pageList, setPagelist] = useState([])
    const { apiToken, setApiToken } = useApiToken()

    const DATE_FIX_FORMAT_OPTIONS = { 
        year: 'numeric', month: '2-digit', day: '2-digit', 
        hour: '2-digit', minute: '2-digit', hour12: false 
    }
    
    useEffect(() => {
        const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
        const getConfigPages = async () => {

            fetch(CP_API,
                {
                    headers: {
                        'Authorization': `Bearer ${apiToken}`
                    }
                })
                .then((response) => response.json())
                .then((responseData) => {
                    //console.log(responseData)
                    setPagelist(responseData)
                })
                .catch((e) => {
                    console.error(e)
                    setApiToken('')
                })
        }

        if (!apiToken || apiToken.length < 20) {
            return
        }
        getConfigPages()
    }, [apiToken, setApiToken])

    return (<>
        <table id="configpagestable">
            <thead><tr>
                <th colSpan="2">Metadata</th>
                <th colSpan="2" >Title</th>
                <th colSpan="3">Content</th>
            </tr></thead>
            <tbody>
                {pageList.map((cp) => {
                    return (<tr key={cp._id}>                        
                        <td colSpan="2"><ul>
                            <li><small>ID: {cp._id}</small></li>
                            <li>Created: <time>{
                                (new Intl.DateTimeFormat('hu-HU', DATE_FIX_FORMAT_OPTIONS))
                                .format(new Date(cp.createdAt))
                            }</time></li>
                            <li>Updated <time>{
                                (new Intl.DateTimeFormat('hu-HU', DATE_FIX_FORMAT_OPTIONS))
                                .format(new Date(cp.updatedAt))
                            }</time></li>
                        </ul></td>
                        <td colSpan="2">
                            <Link to={`/configpage/${cp._id}`}>{cp.title}</Link>
                        </td>
                        <td colSpan="3" className="configpagestableContentCell">{cp.content.substring(0,120)}</td>
                    </tr>)
                })}
            </tbody>
        </table>
    </>)

}