import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'
import { Link } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

const LOCAL_STORAGE_PREFIX = `${(process.env.REACT_APP_LOCAL_STORAGE_PREFIX || 'dash-kdanni-hu::')}w2w::`
//localStorage.setItem(LOCAL_STORAGE_PREFIX + 'showStorytime', showStorytime)
//localStorage.getItem(LOCAL_STORAGE_PREFIX + 'showStorytime')

function Wiki1PageList() {

    const { apiToken } = useApiToken()
    let [searchParams] = useSearchParams()
    let w2SearchParam = searchParams.has('w2')


    const [localStoreIntialed, setLocalStoreIntialed] = useState(false)
    const [w1Api, setW1Api] = useState('')
    const [w1Bot, setW1Bot] = useState('')
    const [w1BotPass, setW1BotPass] = useState('')
    const [w2Api, setW2Api] = useState('')
    const [w2Bot, setW2Bot] = useState('')
    const [w2BotPass, setW2BotPass] = useState('')

    const [wiki, setWiki] = useState(null)
    const [namespaces, setNamespaces] = useState([])
    const [pagelist, setPagelist] = useState([])
    // const [pageMap, setPageMap] = useState({})
    
    useEffect(() => {
        setWiki(null);
    }, [w2SearchParam])

    useEffect(() => {
    if(localStoreIntialed) {
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1Api', w1Api)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1Bot', w1Bot)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1BotPass', btoa(w1BotPass))
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2Api', w2Api)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2Bot', w2Bot)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2BotPass', btoa(w2BotPass))
    } else {
        setW1Api(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1Api'))
        setW1Bot(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1Bot'))
        setW1BotPass(atob(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1BotPass')||''))
        setW2Api(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2Api'))
        setW2Bot(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2Bot'))
        setW2BotPass(atob(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2BotPass')||''))
        setLocalStoreIntialed(true)
    }
    }, [w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])


    useEffect(() => {
        if(wiki === null){
            return;
        }
        if(!namespaces || namespaces?.length < 1){
            return;
        }
        let wapi = w1Api;
        let wbot = w1Bot;
        let wpass = w1BotPass;
        if(w2SearchParam){
            wapi = w2Api;
            wbot = w2Bot;
            wpass = w2BotPass;
        }

        if(!localStoreIntialed || !wapi || !wbot || !wpass
            || '' === `${wapi}` || '' === `${wbot}` || '' === `${wpass}`) 
        {
            return;
        }
        const W2W_API = process.env.REACT_APP_KDANNI_W2W_API_URL;

        

        const getWikiPages = async () => {
            for(const ns of namespaces) {
                if(ns.id < 0){
                    continue;
                }
                fetch(W2W_API,
                    {
                        method: 'POST',                    
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${apiToken}`
                        },
                        body: JSON.stringify({
                            wiki: {
                                api: wapi, 
                                bot: wbot,
                                pass: wpass
                            },
                            queryData: {
                                action: 'query',
                                list: 'allpages',
                                aplimit: 'max',
                                apnamespace: `${ns.id}`,
                                format: 'json'
                            }
                        })
                    }
                )
                .then((response) => response.json())
                .then((responseData) => {
                    const pages = [];
                    // console.log(responseData);                
                    const {success, wikiResponse} = responseData;
                    // console.log(success, wikiResponse);                
                    if(success && wikiResponse?.query?.allpages){
                        
                        for(const onePage of (wikiResponse.query.allpages||[])){
                            onePage.key = `${ns.id}-${onePage.pageid}`;
                            onePage.namespaceName = ns['*'];
                            pages.push(onePage);
                        }

                        setPagelist(prevData => {
                            return [].concat(prevData, pages)
                        });
                    } else {
                        console.error('No all pages.', responseData);    
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
            }
        }
        getWikiPages()
    }, [apiToken, wiki, w2SearchParam, w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed, namespaces])

 
    useEffect(() => {
        let wapi = w1Api;
        let wbot = w1Bot;
        let wpass = w1BotPass;
        if(w2SearchParam){
            wapi = w2Api;
            wbot = w2Bot;
            wpass = w2BotPass;
        }
        
        if(!localStoreIntialed || !wapi || !wbot || !wpass
            || '' === `${wapi}` || '' === `${wbot}` || '' === `${wpass}`) 
        {
            return;
        }
        const W2W_API = process.env.REACT_APP_KDANNI_W2W_API_URL;

        const getWikiNamespaces = async () => {
            fetch(W2W_API,
                {
                    method: 'POST',                    
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify({
                        wiki: {
                            api: wapi, 
                            bot: wbot,
                            pass: wpass
                        },
                        queryData: {
                            action: 'query',
                            meta: 'siteinfo',
                            siprop: 'namespaces',
                            format: 'json'
                        }
                    })
                }
            )
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData);                
                const {success, wikiResponse} = responseData;
                // console.log(success, wikiResponse);                
                if(success && wikiResponse?.query?.namespaces){
                    const nsArray = [];
                    for(const oneNsProp in (wikiResponse.query.namespaces||{})){
                        const oneNs = wikiResponse.query.namespaces[oneNsProp];
                        oneNs.key = oneNs.id;
                        nsArray.push(oneNs);
                    }
                    setPagelist([])
                    setNamespaces(nsArray);
                    setWiki(w2SearchParam?2:1);
                } else {
                    console.error('No namespaces.', responseData);    
                }
            })
            .catch((e) => {
                console.error(e)
            })
        }
        getWikiNamespaces()
    }, [apiToken, w2SearchParam, w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])

    return (
        <>
            <div id="w2w-w1-pagelist">
            <h1>Wiki {w2SearchParam?'2':'1'} Page List</h1>
            <section><Link to={'/w2w/'}>Wiki 2 Wiki</Link>| <Link to={'/wiki1pagelist/' + (w2SearchParam?'':'?w2=w2')}>Wiki {w2SearchParam?'1':'2'} Page List</Link></section>
            <section>
                <h2>Config info</h2>
                <p><label>
                    Wiki {w2SearchParam?'2':'1'} API URL
                    <input value={w2SearchParam?w2Api:w1Api} type="text" className="w2w-conf-input" readOnly={true}></input>
                </label></p>
                <p><label>
                    Wiki {w2SearchParam?'2':'1'} Bot Name
                    <input value={w2SearchParam?w2Bot:w1Bot} type="text" className="w2w-conf-input"  readOnly={true}></input>
                </label></p>
            </section>
            <section>
                <h2>Namespaces</h2>
                {namespaces.map((ns) => {
                    // return (<pre>{JSON.stringify(ns)}</pre>)
                    return(<dd key={ns.key}>{ns['*']} &nbsp; <small>({ns.id})</small></dd>)                    
                })}
            </section>
            <section>
                <h2>Pages</h2>
                <ol>
                {pagelist.map((wp) => {
                    // return (<pre>{JSON.stringify(wp)}</pre>)
                    if(wiki !== null) {
                        return(<li key={wp.key}><Link to={'/wiki1page/' + wp.pageid + (w2SearchParam?'?w2=w2':'')}>{wp.title}</Link></li>)
                    } else {
                        return(<></>)
                    }
                })}
                </ol>
            </section>
            </div>
        </>
    )
}

export default Wiki1PageList