import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useApiToken } from '../context/ApiTokenContext'
import jwtDecode from 'jwt-decode'

function UserProfile() {
  const { user, isAuthenticated, isLoading } = useAuth0()
  const {apiToken} = useApiToken()
  
  const userContent = () => {
    return <>
      <pre style={{fontSize: '16px'}}>{JSON.stringify(user, null, 2)}</pre>
    </>
  }

  const cpApiTokenContent = () => {
    return <>
      <p><code>{apiToken}</code></p>
      <pre style={{fontSize: '16px'}}>{JSON.stringify(jwtDecode(apiToken), null, 2)}</pre>
    </>
  }

  return (
    <>
        {!isLoading && !isAuthenticated && (<p>"Not authenticated"</p>) }
        {isLoading && <h1 style={{color:'red'}}>Loading!</h1>}
        {!isLoading && isAuthenticated && userContent()}
        { apiToken ? (cpApiTokenContent()) : (<p>"No cp API token"</p>)}
        {/* <ConfigPageDisplay /> */}
    </>
  )
}

export default UserProfile