import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'
import { Link } from 'react-router-dom'
import { Navigate } from 'react-router-dom';

const LOCAL_STORAGE_PREFIX = process.env.REACT_APP_LOCAL_STORAGE_PREFIX || 'dash-kdanni-hu::'

function ConfigPageEditor(props) {
    const {id, rootpage} = props;

    const { apiToken, setApiToken } = useApiToken()
    const [thePage, setThePage] = useState({})
    const [underEdit, setUnderEdit] = useState({})
    const [savedPage, setSavedPage] = useState(null)
    const [resetPage, setResetPage] = useState('Reset')
    const [resetPageConfirmed, setResetPageConfirmed] = useState(false)
    const [deletePage, setDeletePage] = useState('Delete')
    const [deletePageConfirmed, setDeletePageConfirmed] = useState(false)
    const [pageUpdated, setPageUpdated] = useState(null)
    const [pageDeleted, setPageDeleted] = useState(null)
    
    useEffect(() => {
        if(underEdit && underEdit.content) {
            // console.log("localStorage.setItem('underEditPage')", underEdit)
            localStorage.setItem(LOCAL_STORAGE_PREFIX + 'underEditPage', JSON.stringify(underEdit))
        }
    }, [underEdit, underEdit?.content])

    useEffect(() => {
        if(savedPage) {
            setResetPageConfirmed(false)
            setResetPage('Reset')
            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                },
                body: JSON.stringify({...savedPage})
            }
            const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
            const CP_PATH = rootpage ? '' : `${id}/` 

            const putConfigPages = async () => {
                fetch(`${CP_API}${CP_PATH}`, requestOptions)
                    .then( () => { setPageUpdated((new Date()).getTime()) })
                    .catch(err => { 
                            setPageUpdated((new Date()).getTime())
                            console.error(err) 
                        })
            }
            
            if (!apiToken || apiToken.length < 20) {
                return
            }
            putConfigPages()
        }
    }, [savedPage, id, rootpage, apiToken])

    useEffect(() => {
        const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
        const CP_PATH = rootpage ? 'rootpage/' : `id/${id}/` 
        
        let underEditStored = localStorage.getItem(LOCAL_STORAGE_PREFIX + 'underEditPage')
        if(underEditStored) {
            underEditStored = JSON.parse(underEditStored)
        } 
        // console.log("localStorage.getItem(LOCAL_STORAGE_PREFIX + 'underEditPage')", underEditStored)
        
        const getConfigPages = async () => {

            fetch(`${CP_API}${CP_PATH}`,
                {
                    headers: {
                        'Authorization': `Bearer ${apiToken}`
                    }
                })
                .then((response) => {
                    if(response.ok) {
                        return response.json()
                    } else {
                        const ret = async () => { 
                            let val = 'Error:' + response.status + '\n'
                            val += await response.text()
                            for (const header of response.headers.entries()) {
                                val += '\n\t' + JSON.stringify(header)
                            }
                            return { content: val }
                        }
                        return ret()
                    }
                })
                .then((responseData) => {
                    //console.log(responseData)
                    if(responseData.message) {
                        setThePage({content: 'Error\n' + JSON.stringify(responseData)})
                        throw new Error(responseData.message)
                    }
                    setResetPageConfirmed(false)
                    setResetPage('Reset')
                    setThePage(responseData)
                    // console.log('_ID', responseData?._id , underEditStored?._id,
                    //     responseData?._id !== underEditStored?._id)
                    if(responseData && responseData?._id !== underEditStored?._id) {
                        // console.log('SET REMOTE')
                        setUnderEdit(responseData)
                    } else {
                        // console.log('SET LOCAL')
                        setUnderEdit(underEditStored)
                    }
                })
                .catch((e) => {                    
                    console.error('Get config page Error', e)
                    setApiToken('')
                    setUnderEdit(underEditStored)
                })
        }

        if (!apiToken || apiToken.length < 20) {
            return
        }
        getConfigPages()
    }, [id, rootpage, apiToken, setApiToken, pageUpdated])

    const handleContentChange = (event) => {
        setUnderEdit( prev => {
            return {...prev, content: event.target.value}
        })
    }

    useEffect(() => {
        if(resetPage === 'Confirm' && resetPageConfirmed) {
            console.log('RESET Effect', resetPage, resetPageConfirmed) 
            setResetPageConfirmed(false)
            setResetPage('Reset')
            setUnderEdit(thePage)
        }
    }, [thePage, resetPage, resetPageConfirmed])

    const handleResetClick = () => {
        console.log('RESET', resetPage, resetPageConfirmed) 
        if(resetPage !== 'Confirm') {
            setResetPage('Confirm')
        } else {
            setResetPageConfirmed(true)
        }
    }

    useEffect(() => {
        if(deletePage === 'Confirm Delete!' && deletePageConfirmed) {
            console.log('DELETE Effect', deletePage, deletePageConfirmed) 
            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Authorization': `Bearer ${apiToken}`
                }
            }
            const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
            const CP_PATH = `${id}/` 

            const deleteConfigPage = async () => {
                fetch(`${CP_API}${CP_PATH}`, requestOptions)
                    .then(() => { setPageDeleted((new Date()).getTime()) })
                    .catch(err => { 
                            setPageUpdated((new Date()).getTime())
                            console.error(err) 
                        })
            }
            
            if (!apiToken || apiToken.length < 20) {
                return
            }
            deleteConfigPage()
        }
    }, [deletePage, deletePageConfirmed, apiToken, id])

    const handleDeleteClick = () => {
        console.log('DELETE', deletePage, deletePageConfirmed) 
        if(deletePage !== 'Confirm Delete!') {
            setDeletePage('Confirm Delete!')
        } else {
            setDeletePageConfirmed(true)
        }
    }

    const handleSaveClick = () => {
        setSavedPage({...underEdit})       
    }

    
    if(pageDeleted) {
        return (<Navigate to='/configpage' />)
    }
    
    return (
        <>
            <h2 className="theConfigpageDisplayH2">
                { rootpage 
                    ? (<Link to={`/configpage/rootpage`}>Root Page</Link>)
                    : (<Link to={`/configpage/${id}`}>{thePage?.title}</Link>)
                }
            </h2>

            <h3 className="theConfigpageEditorH3">Edited Content</h3>
            <textarea className="theConfigpageDisplayTextarea" 
                value={underEdit?.content} 
                onChange={handleContentChange} 
            />
            <br />
            <section className="theConfigpageEditorButtonSection">
                {!rootpage && (<><button id="editorDeleteButton" onClick={handleDeleteClick} >{deletePage}</button>
                &nbsp; &nbsp; &nbsp; &nbsp;</>)}
                <button id="editorResetButton" onClick={handleResetClick} >{resetPage}</button>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button id="editorSaveButton" onClick={handleSaveClick} >Save</button>
            </section>            

            <h3 className="theConfigpageEditorH3">Saved Content</h3>
            <textarea className="theConfigpageDisplayTextarea" value={thePage?.content} readOnly={true} />
        </>
    )
}

export default ConfigPageEditor