import { Link } from "react-router-dom";

export default function Nocontent() {
  return (
    <>
        <Link to="/">
            <h1 className='headerH1'>Home</h1>
        </Link>
    </>
  );
}