import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from './LoginButton'

export default function AuthContentWrapper(props) {
    const { isAuthenticated, isLoading } = useAuth0()

    const notAuthenticated = () => {
        return <><LoginButton /></>
    }

    const authenticated = () => {
        return <>{props.children}</>
    }

    return (
        <>
            {isLoading && (<h1 style={{ color: 'red' }}>Loading...</h1>)}
            {!isLoading && !isAuthenticated && notAuthenticated()}
            {!isLoading && isAuthenticated && authenticated()}
        </>
    )

}