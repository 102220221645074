import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'
import ConfigPageList from "./ConfigpageList"
import ConfigPageDisplay from "./ConfigPageDisplay"
import ConfigPageEditor from "./ConfigPageEditor"
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'


function ConfigPage() {
  let { id } = useParams()
  let [searchParams] = useSearchParams();
  let edit = searchParams.has('edit')
  let rootSearchParam = searchParams.has('root')
  // console.log('Edit', edit)

  const { apiToken } = useApiToken()

  const [newPageTitle, setNewPageTitle] = useState('')
  const [newPageTitleToPost, setNewPageTitleToPost] = useState(null)
  const [enableCreate, setEnableCreate] = useState(false)
  const [pageCreated, setPageCreated] = useState(null)

  useEffect(() => {
    if(!pageCreated){
      return
    }
    setNewPageTitle('')
    setNewPageTitleToPost(null)
    setEnableCreate(false)
    window.location.reload()
  }, [pageCreated])

  useEffect(() => {
    if (newPageTitle && newPageTitle.length > 3) {
      //console.log('Valid Page Title')
      const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL

      fetch(`${CP_API}title/${newPageTitle}/`,
        {
          headers: {
            'Authorization': `Bearer ${apiToken}`
          }
        })
        .then((response) => {
          if (response.ok) {
            return response.json()
            // setEnableCreate(false)
          } else {
            setEnableCreate(false)
          }
        })
        .then((responseData) => {
          if(responseData.message) {
            setEnableCreate(false)
          } else {
            if(Array.isArray(responseData) && responseData.length === 0) {
              setEnableCreate(true)
            } else {
              setEnableCreate(false)
            }
          }
        })
        .catch((e) => {
          setEnableCreate(false)
          console.log(e)
        })
    } else {
      setEnableCreate(false)
    }
  }, [newPageTitle, apiToken])

  useEffect(() => {
    if (!newPageTitleToPost || !newPageTitleToPost.length || newPageTitleToPost.length < 3) {
      return
    }
    const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
    fetch(`${CP_API}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiToken}`
        },
        body: JSON.stringify({ content: '#NOP', title: newPageTitleToPost })
      })
      .then((response) => {
        if (response.ok || response.status === 201) {
          return response.json()
        } else {
          throw new Error('Not created')
        }
      })
      .then((responseData) => {
        if(responseData.message) {
          throw new Error(responseData.message)
        } else {
          setPageCreated(responseData)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [newPageTitleToPost, apiToken])

  const handleNewPageTitleChange = (event) => {
    setNewPageTitle(event.target.value)
  }

  const handleCreateNewPage = () => {
    console.log('Create New Page', newPageTitle)
    setNewPageTitleToPost(newPageTitle)
  }


  if (!edit && id === 'rootpage') {
    return (
      <>
        {!rootSearchParam && <ConfigPageDisplay rootpage={true} />}
        {rootSearchParam && 
          (<>
            <Link to={'/configpage/'}>Configpages</Link>
            <ConfigPageDisplay rootpage={true} hideRawContent={true} />
          </>)}
      </>
    )
  }
  if (edit && id === 'rootpage') {
    return (
      <>
        <ConfigPageEditor rootpage={true} />
      </>
    )
  }

  if (!edit && id) {
    return (
      <>
        <ConfigPageDisplay id={id} />
      </>
    )
  }
  if (edit && id) {
    return (
      <>
        <ConfigPageEditor id={id} />
      </>
    )
  }

  return (
    <>
      <h2 className="theConfigpageDisplayH2">
        <Link to={'/configpage/rootpage'}>Rootpage</Link>
        &nbsp; &nbsp; - &nbsp; &nbsp;
        <small><Link to={'/configpage/rootpage?root=true'}>root</Link></small>  
      </h2>
      <ConfigPageDisplay rootpage={true} hideRawContent={true} />
      <section id="createConfigPageSection">
        <input
          type="text"
          placeholder="Page Title"
          value={newPageTitle}
          onChange={handleNewPageTitleChange}
        /> &nbsp; &nbsp; &nbsp;
        <button onClick={handleCreateNewPage} disabled={!enableCreate}>Create Config Page</button>
      </section>
      <ConfigPageList />
    </>
  )
}

export default ConfigPage