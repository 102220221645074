import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const LOCAL_STORAGE_PREFIX = `${(process.env.REACT_APP_LOCAL_STORAGE_PREFIX || 'dash-kdanni-hu::')}w2w::`
//localStorage.setItem(LOCAL_STORAGE_PREFIX + 'showStorytime', showStorytime)
//localStorage.getItem(LOCAL_STORAGE_PREFIX + 'showStorytime')

function Wiki2WikiHome() {
    const [localStoreIntialed, setLocalStoreIntialed] = useState(false)
    const [w1Api, setW1Api] = useState('')
    const [w1Bot, setW1Bot] = useState('')
    const [w1BotPass, setW1BotPass] = useState('')
    const [w2Api, setW2Api] = useState('')
    const [w2Bot, setW2Bot] = useState('')
    const [w2BotPass, setW2BotPass] = useState('')


    useEffect(() => {
    if(localStoreIntialed) {
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1Api', w1Api)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1Bot', w1Bot)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1BotPass', btoa(w1BotPass))
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2Api', w2Api)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2Bot', w2Bot)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2BotPass', btoa(w2BotPass))
    } else {
        setW1Api(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1Api'))
        setW1Bot(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1Bot'))
        setW1BotPass(atob(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1BotPass')||''))
        setW2Api(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2Api'))
        setW2Bot(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2Bot'))
        setW2BotPass(atob(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2BotPass')||''))
        setLocalStoreIntialed(true)
    }
    }, [w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])


    const w1ApiChange = (e) => setW1Api(e.target.value);
    const w1BotChange = (e) => setW1Bot(e.target.value);
    const w1BotPassChange = (e) => setW1BotPass(e.target.value);

    const w2ApiChange = (e) => setW2Api(e.target.value);
    const w2BotChange = (e) => setW2Bot(e.target.value);
    const w2BotPassChange = (e) => setW2BotPass(e.target.value);
 
    return (
        <>
            <div id="w2w-home">
            <h1>Wiki 2 Wiki</h1>
            <section>
                <Link to={'/wiki1pagelist/'}>Wiki 1 Page List</Link>

            </section>
            <section>
                <h2>Wiki 1</h2>
                <p><label>
                    Wiki 1 API URL
                    <input value={w1Api} onChange={w1ApiChange} type="text" className="w2w-conf-input"></input>
                </label></p>
                <p><label>
                    Wiki 1 Bot Name
                    <input value={w1Bot} onChange={w1BotChange} type="text" className="w2w-conf-input"></input>
                </label></p>
                <p><label>
                    Wiki 1 Bot Pass
                    <input value={w1BotPass} onChange={w1BotPassChange} type="password" className="w2w-conf-input"></input>
                </label></p>
            </section>
            <section>
                <h2>Wiki 2</h2>
                <p><label>
                    Wiki 2 API URL
                    <input value={w2Api} onChange={w2ApiChange} type="text" className="w2w-conf-input"></input>
                </label></p>
                <p><label>
                    Wiki 2 Bot Name
                    <input value={w2Bot} onChange={w2BotChange} type="text" className="w2w-conf-input"></input>
                </label></p>
                <p><label>
                    Wiki 2 Bot Pass
                    <input value={w2BotPass} onChange={w2BotPassChange} type="password" className="w2w-conf-input"></input>
                </label></p>
            </section>
            </div>
        </>
    )
}

export default Wiki2WikiHome