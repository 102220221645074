import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'

const LOCAL_STORAGE_PREFIX = process.env.REACT_APP_LOCAL_STORAGE_PREFIX || 'dash-kdanni-hu::'
//localStorage.setItem(LOCAL_STORAGE_PREFIX + 'showStorytime', showStorytime)
//localStorage.getItem(LOCAL_STORAGE_PREFIX + 'showStorytime')

function FDashboard() {
  const [localStoreIntialed, setLocalStoreIntialed] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [showSpam, setShowSpam] = useState(false)
  const [showStorytime, setShowStorytime] = useState(false)
  const [threadList, setThreadList] = useState([])
  const [rawDashData, setRawDashData] = useState(null)
  const [dashData, setDashData] = useState(null)
  const [putDashData, setPutDashData] = useState(null)
  const [catalog, setCatalog] = useState(null)
  const [catalogMap, setCatalogMap] = useState(null)
  const { apiToken, setApiToken } = useApiToken()

//   const DATE_FIX_FORMAT_OPTIONS = { 
//       year: 'numeric', month: '2-digit', day: '2-digit', 
//       hour: '2-digit', minute: '2-digit', hour12: false 
//   }

  // eslint-disable-next-line no-unused-vars
  const pharseCom = (rawcom) => {
    rawcom = rawcom || ''
    if (rawcom === '') {
        return rawcom
    }
    let com = rawcom.replace(/<wbr>/ig, '')
    if (com === '') {
        return com 
    }
    com = com.replace(/<br>/igm, ' ')
    com = com.replace(/<img[^>]+src[^>]+>/ig, '')
    com = com.replace(/<a[^>]+href="[^"]+"[^>]*>(.*)<\/a>/ig, '')
    com = com.replace(/(<([^>]+)>)/ig, '')
    com = com.replace(/>>\d+/igm, '')
    com = com.replace(/&gt;&gt;\d+/igm, '')
    com = com.replace(/^ +/, '')
    return com
  }

  const cleanPattern = (pattern) => {
    
    pattern = pattern.replace(/\\W\?/ig, ' ');
    pattern = pattern.replace(/\\W\*/ig, ' ');
    pattern = pattern.replace(/\\W\+/ig, ' ');
    pattern = pattern.replace(/\\W/ig, ' ');
    pattern = pattern.replace(/\(\?=\.\*\\b/g, '');
    pattern = pattern.replace(/\\b\)/g, ' ');
    pattern = pattern.replace(/\\b/g, '');
    pattern = pattern.replace(/\\/g, '');
    pattern = pattern.replace(/\?/ig, '');
    pattern = pattern.replace(/\*/ig, '');
    pattern = pattern.replace(/\+/ig, '');
    pattern = pattern.replace(/\/i$/ig, '/');
    pattern = pattern.replace(/ $/, '');

    return pattern
  }

  const fullFilterData = (filterData) => {
    let ret = ''
    if((filterData.match||[]).length < 2) {
      return ''
    }
    for(const match of (filterData.match||[])) {
      ret += `${cleanPattern(match.line)} (${match.name}), `
    }
    ret = ret.substring(0,ret.length-2)
    return ret
  }

  const filter0Data = (filterData) => {
    if(!filterData || filterData.match?.length < 1){
      return { pattern:'', feed:''}
    }
    let filter0 = {line:'', priority: Number.MAX_SAFE_INTEGER}

    filter0 = filterData.match.reduce(
      (prew, curr) => {
        if(prew.priority <= curr.priority) {
          return prew
        } else {
          return curr
        }
      },
      filter0
    )

    filter0 = filter0 || {}
    let pattern = filter0?.line || ''
    pattern = cleanPattern(pattern)
    if(pattern.length < 1){
      return { pattern:'', feed:''}
    }
      
    filter0 = filter0 || {}
    return {feed: filter0.name, pattern}
  }

  useEffect(() => {
    if(localStoreIntialed) {
      localStorage.setItem(LOCAL_STORAGE_PREFIX + 'showStorytime', showStorytime)    
    } else {
      setShowStorytime(`${true}` === localStorage.getItem(LOCAL_STORAGE_PREFIX + 'showStorytime'))
      setLocalStoreIntialed(true)
    }
  }, [showStorytime, localStoreIntialed])
  

  useEffect(() => {
    if(!catalogMap || !rawDashData) {
      return
    }
    
    const FDASH_API = process.env.REACT_APP_KDANNI_4DASH_API_URL
    const get4Dashboard = async () => {
      //console.log(FDASH_API)
      
    fetch(FDASH_API,
        {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
        .then(response => {
          //console.log(response)
          return response.json()
        })
        .then((responseData) => {
            //console.log(responseData)
            setThreadList(responseData)
        })
        .catch((e) => {
            console.error(e)
            setApiToken('')
        })
  }

  if (!apiToken || apiToken.length < 20) {
      return
  }
  get4Dashboard()
}, [apiToken, setApiToken, catalogMap, rawDashData])

useEffect(() => {
  if(!rawDashData || !catalogMap || threadList.length < 1) {
    return
  }
  rawDashData.threadData = rawDashData.threadData || {}
  const threadDashData = {}
  //console.log(catalogMap)
  for(const thread of threadList) {
    const op = catalogMap[thread.board + thread.no]
    if(op) {
      // console.log(op)
      const oldData = rawDashData.threadData[thread.board + thread.no]
      let newData = {}
      if(oldData) {
        newData = {...oldData}
        newData.newThread = false
        newData.newReplies = op.replies - oldData.replies
        newData.replies = op.replies
      } else {
        newData = {
          replies : op.replies,
          newThread: true,
          newReplies: op.replies
        }
      }
      threadDashData[thread.board + thread.no] = newData
    }
  }
  const newDashData = {...rawDashData}
  newDashData.threadData = threadDashData
  setDashData(newDashData)
  setPutDashData(newDashData)

}, [threadList, catalogMap, rawDashData] )

// https://a.4cdn.org/a/catalog.json

useEffect(() => {
    const FDASH_API = process.env.REACT_APP_KDANNI_4DASH_API_URL
    //const CATALOG_URL = 'https://a.4cdn.org/a/catalog.json'
    const getCatalog = async () => {
      //console.log(FDASH_API)
      fetch(FDASH_API + 'catalog.json',
            {
              headers: {
                'Authorization': `Bearer ${apiToken}`
              }
            }
          )
          .then(response => {
            //console.log(response)
            return response.json()
          })
          .then((responseData) => {
              //console.log(responseData)
              setCatalog(responseData)
          })
          .catch((e) => {
              console.error(e)
              setApiToken('')
          })
  }
  getCatalog()
}, [apiToken, setApiToken, refresh, showSpam, showStorytime] )

useEffect(() => {
  const FDASH_API = process.env.REACT_APP_KDANNI_4DASH_API_URL
  //const CATALOG_URL = 'https://a.4cdn.org/a/catalog.json'
  const getDashData = async () => {
    //console.log(FDASH_API)
    fetch(FDASH_API + 'data.json',
          {
            headers: {
              'Authorization': `Bearer ${apiToken}`
            }
          }
        )
        .then(response => {
          //console.log(response)
          return response.json()
        })
        .then((responseData) => {
            //console.log(responseData)
            setRawDashData(responseData)
        })
        .catch((e) => {
            console.error(e)
            setApiToken('')
        })
}

getDashData()
}, [apiToken, setApiToken, refresh, showSpam, showStorytime] )


useEffect(() => {
  const FDASH_API = process.env.REACT_APP_KDANNI_4DASH_API_URL
  //const CATALOG_URL = 'https://a.4cdn.org/a/catalog.json'
  const puDashDataToServer = async () => {
    //console.log(putDashData)
    if(putDashData === null){
      return
    }
    //console.log(FDASH_API)
    fetch(FDASH_API + 'data.json',
          {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${apiToken}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(putDashData)
          }
        )
        .then(response => {
          //console.log(response)
          return response.json()
        })
        .then((responseData) => {
          //console.log(responseData)
        })
        .catch((e) => {
            console.error(e)
            setApiToken('')
        })
  }
  puDashDataToServer()
}, [apiToken, setApiToken, putDashData] )

useEffect(() => {
  if(!catalog || !catalog.length) {
    return
  }
  const map = {}
  for(const board of catalog) {
    for(const page of board.catalog) {
      for(const thread of page.threads) {
        if(!thread.no){
          continue
        }
        map[board.board + thread.no] = {...thread, page: page.page, board: board.board}
      }
    }
  }
  setCatalogMap(map)

}, [catalog] )

const checkThread = (board, no) => {
  const thread = catalogMap[board + no]
  if(!thread){
    return false
  }
  return true
}

const getStats = (board, no) => {
  if(!catalogMap){
    return ''
  }
  const thread = catalogMap[board + no]
  //console.log('Thread', thread)
  if(!thread){
    return ''
  }
  let stats = `${('' + thread.replies).padStart(3,'\u00a0')}/${('' + thread.images).padEnd(3,'\u00a0')}/${thread.page}`
  
  return stats
}

const getNewReplies = (board, no) => {
  //console.log(dashData)
  if(!dashData || !dashData.threadData){
    return ''
  }
  const thread = dashData.threadData[board + no]
  //console.log('Thread data', thread)
  if(!thread){
    return ''
  }
  let newReplies = `${('' + thread.newReplies).padStart(3,'\u00a0')}`
  
  if(thread.newReplies < 1) {
    return <span className="fcopl-new">{newReplies}</span>
  } else {
    return <span className="fcopl-new fcopl-new-red">{newReplies}</span>
  }
}

// const getImgSrc = (thread) => {
//   const board = thread.board
//   const op = thread.op
//   if(!op?.tim || !board){
//     return ''
//   }
//   return `https://i.4cdn.org/${board}/${op.tim}s.jpg`
  
// }

const xThread = (thread) => {
    //console.log(thread)
    setDashData((prevData) => {
      const newData = prevData || {}
      const filtered = {}
      for(const ft in (newData.filter || {})){
        const cat = catalogMap[ft]
        if(cat) {
          filtered[ft] = true
        }
      }
      filtered[thread.board + thread.no] = true
      newData.filter = filtered
      setPutDashData({...newData})
      return {...newData}
    })    
}

const checkDashDataFilter = (board, no) => {
    const map = dashData?.filter || {}
    const thread = map[board + no]
    if(thread === true){
      return true
    } else {
      return false
    }
}

const checkFilterPrio = (filterData) => {
  if(!filterData) {
    return true
  }
  let ok = false
  for (const match of (filterData.match || [])) {
    //console.log(match)
    if(match.board === 'u'){
      ok = true
      break
    }
    if(
      match?.name === '****' ||
      match?.name === 'sss' ||
      match?.name === 'markread'
    ) {
      continue
    } 
    ok = true
    break
  }

  return ok
}

const hideSpam = (filterData) => {
  if(showSpam) {
    return false
  }
  if(!filterData) {
    return true
  }
  let hide = true
  for (const match of (filterData.match || [])) {
    //console.log(match)
    if(match.board !== 'a'){
      hide = false
      break
    }
    if(
      match?.name === 'spm' ||
      match?.name === 'sss' ||
      match?.name === 'ssss' ||
      match?.name === 'lowprio' ||
      match?.name === 'markread'
    ) {
      continue
    }
    if(
      !showStorytime &&
      (
        match?.name === 'storytime' ||
        match?.name === 'planed' ||
        match?.name === '*'
      )
    ) {
      continue
    }    
    hide = false
    break
  }

  return hide
}

const hideStoryTime = (filterData) => {
  if(showSpam) {
    return false
  }
  if(showStorytime) {
    return false
  }
  if(!filterData) {
    return true
  }
  let hide = true
  for (const match of (filterData.match || [])) {
    //console.log(match)
    if(match.board === 'u'){
      hide = false
      break
    }
    if(
      match?.name === 'storytime' ||
      match?.name === 'planed' ||
      match?.name === '*' ||
      match?.name === 'spm' ||
      match?.name === 'sss' ||
      match?.name === 'ssss' ||
      match?.name === 'lowprio' ||
      match?.name === 'markread'
    ) {
      continue
    } 
    hide = false
    break
  }

  return hide
}

const handleSubjectTextChange = (event, thread) => {
  // console.log(event.type, event.code, event.target.value, thread.board, thread.no)
  if(event.code === 'Enter' || event.code === 'NumpadEnter' || event.type === 'blur') {    
    setDashData( prevData => {
      let newData = prevData || {}
      newData.threadData = newData.threadData || {}
      newData.threadData[thread.board + thread.no] = newData.threadData[thread.board + thread.no] || {}
      newData.threadData[thread.board + thread.no].subjectOverride = event.target.value
      console.log(event.code, event.target.value, thread.board, thread.no)
      setPutDashData({...newData})
      return {...newData}
    })
  }
}

const getSubject = (thread) => {
  let sub = thread.op.sub
  const ddThread = dashData?.threadData[thread.board + thread.no]
  if(ddThread && ddThread.subjectOverride){
    sub = ddThread.subjectOverride
  }
  return <span className="fcopl-sub">
          <a href={`https://boards.4chan.org/${thread.board}/thread/${thread.op.no}`} target="_blank" rel="noreferrer">
          {(sub||filter0Data(thread.filterData).pattern)}</a>
        </span>
}


return (
    <>
      <div id="fdashboard-refresh">
        <button onClick={() => setRefresh((refresh) => !refresh)}>Refresh</button>
        <button onClick={() => setShowStorytime((showStorytime) => !showStorytime)}>Toggle Story Time</button>
        <button onClick={() => setShowSpam((showSpam) => !showSpam)}>Toggle Spam</button>        
      </div>
      <div id="fdashboard">
        {threadList.map((thread) => {
            if(!checkThread(thread.board, thread.no)){
              return ''
            }
            if(!checkFilterPrio(thread.filterData)){
              return ''
            }
            if(checkDashDataFilter(thread.board, thread.no)){
              return ''
            }            
            if(hideStoryTime(thread.filterData)){
              return ''
            }
            if(hideSpam(thread.filterData)){
              return ''
            }
            return (
              <div key={thread._id} className="fchanThreadOPline">
                {/* {(thread.op.com ? (<small className="fcopl-com"> */}
                {(fullFilterData(thread.filterData) !== '' ? (<small className="fcopl-com">
                  {/* {pharseCom(thread.op.com).substring(0,200)} */}
                  {fullFilterData(thread.filterData)}
                  </small>) : '')}
                <span className="fcopl-filter">
                  <a href={`https://boards.4chan.org/${thread.board}/thread/${thread.op.no}`} target="_blank" rel="noreferrer">
                  ({filter0Data(thread.filterData).feed}) [{`${filter0Data(thread.filterData).pattern}`}]</a>
                </span>
                {<span className="fcopl-stats">{getStats(thread.board, thread.no)}</span>}
                <span className="fcopl-board">/{thread.board}/ &nbsp; </span>
                <button className="fcopl-x-button" onClick={()=>{xThread(thread)} }>x</button>
                {getNewReplies(thread.board, thread.no)}
                {getSubject(thread)}
                <input onKeyDown={(event) => {handleSubjectTextChange(event,thread)} } 
                  onBlur={(event) => {handleSubjectTextChange(event,thread)} }
                  type="text" className="fcopl-sub-input"></input>              
              </div>
            )
        })}
      </div>
    </>
  )
}

export default FDashboard