import React, {useState, useEffect} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'

const LOCAL_STORAGE_PREFIX = process.env.REACT_APP_LOCAL_STORAGE_PREFIX || 'dash-kdanni-hu::'

const ApiTokenContext = React.createContext()

export function ApiTokenProvider({children}) {
    const [apiToken, setApiToken] = useState('')
    const { user, getAccessTokenSilently } = useAuth0()

    // Local Storage: setting & getting data
    useEffect(() => {
        const getUserMetadata = async () => {
            const cpApiAudience = process.env.REACT_APP_KDANNI_API_AUDIENCE
            const cpApiScope = process.env.REACT_APP_KDANNI_API_SCOPE
      
            try {
                const fetchedApiJwtToken = await getAccessTokenSilently({
                    audience: cpApiAudience,
                    scope: cpApiScope
                })
                //   console.log('getAccessTokenSilently()', cpApiJwtToken)

                setApiToken(fetchedApiJwtToken)

            } catch (e) {
                console.log('getAccessTokenSilently() ERROR: ', e.message)
            }
        }

        // console.log("localStorage.getItem('apiTokenJwt')")
        let fetchNewToken = false;
        const apiTokenJwtStored = localStorage.getItem(LOCAL_STORAGE_PREFIX + 'apiTokenJwt')
        if(apiTokenJwtStored && apiTokenJwtStored.length > 20) {
            try {
                const apiTokenJwtStoredDecoded = jwtDecode(apiTokenJwtStored)
                const {exp, iat} = apiTokenJwtStoredDecoded
                const expire =  new Date(exp * 1000)
                const issued =  new Date(iat * 1000)
                if(expire?.getTime() && issued?.getTime()){
                    // console.log('Api Token Expire at:', expire, 'Issued at', issued)
                    const minus6hours = new Date();
                    minus6hours.setHours(minus6hours.getHours() - 6)
                    fetchNewToken = expire.getTime() < (new Date()).getTime() || issued.getTime() < minus6hours.getTime()
                } else {
                    fetchNewToken = true;
                }
            } catch (e) {
                fetchNewToken = true;
            }
        } else {
            fetchNewToken = true;
        }
        // console.log('Fetch new token?:', fetchNewToken)

        if(!user?.sub) {
            if (apiTokenJwtStored) {
                setApiToken(apiTokenJwtStored)
            }
            return
        }
        if(fetchNewToken) {
            getUserMetadata()
        } else if (apiTokenJwtStored) {
            setApiToken(apiTokenJwtStored)
        }
    }, [getAccessTokenSilently, user?.sub])
    
    useEffect(() => {
        // console.log("localStorage.setItem('apiTokenJwt', apiToken)", apiToken)
        if(apiToken !== '') {
            localStorage.setItem(LOCAL_STORAGE_PREFIX + 'apiTokenJwt', apiToken)
            try {
                //console.log("localStorage.setItem('apiTokenJwt', apiToken)", jwtDecode(apiToken))
                localStorage.setItem(LOCAL_STORAGE_PREFIX + 'apiTokenJwtDecoded', JSON.stringify(jwtDecode(apiToken)))
            } catch (e) {
                //NOP
            }
        }
    }, [apiToken])

    return (
        <ApiTokenContext.Provider value={{apiToken, setApiToken}}>
            {children}
        </ApiTokenContext.Provider>
    )
}

export function useApiToken() {
    const context = React.useContext(ApiTokenContext)
    if (context === undefined) {
      throw new Error('useApiToken must be used within an ApiTokenProvider')
    }
    return context
}