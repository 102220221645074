import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'
import { Link } from 'react-router-dom'
import { useParams, useSearchParams } from 'react-router-dom'

const LOCAL_STORAGE_PREFIX = `${(process.env.REACT_APP_LOCAL_STORAGE_PREFIX || 'dash-kdanni-hu::')}w2w::`
//localStorage.setItem(LOCAL_STORAGE_PREFIX + 'showStorytime', showStorytime)
//localStorage.getItem(LOCAL_STORAGE_PREFIX + 'showStorytime')

function Wiki1Page() {
    let { pageid } = useParams()
    let [searchParams] = useSearchParams()
    let w2SearchParam = searchParams.has('w2')

    const { apiToken } = useApiToken()


    const [localStoreIntialed, setLocalStoreIntialed] = useState(false)
    const [w1Api, setW1Api] = useState('')
    const [w1Bot, setW1Bot] = useState('')
    const [w1BotPass, setW1BotPass] = useState('')
    const [w2Api, setW2Api] = useState('')
    const [w2Bot, setW2Bot] = useState('')
    const [w2BotPass, setW2BotPass] = useState('')


    const [w1PageData, setW1PageData] = useState({})

    const [w1PageWikiText, setW1PageWikiText] = useState(null)


    // const [edit, setEdit] = useState(false)
    const [w2PageName, setW2PageName] = useState('')

    const [w2DoSubmit, setW2DoSubmit] = useState(false)
    const [w2SubmitOkPageDoesntExist, setW2SubmitOkPageDoesntExist] = useState(false)
    const [w2SubmitResult, setW2SubmitResult] = useState(null)

    
    useEffect(() => {
    if(localStoreIntialed) {
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1Api', w1Api)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1Bot', w1Bot)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w1BotPass', window.btoa(w1BotPass))
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2Api', w2Api)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2Bot', w2Bot)    
        localStorage.setItem(LOCAL_STORAGE_PREFIX + 'w2BotPass', window.btoa(w2BotPass))
    } else {
        setW1Api(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1Api'))
        setW1Bot(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1Bot'))
        setW1BotPass(window.atob(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w1BotPass')||''))
        setW2Api(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2Api'))
        setW2Bot(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2Bot'))
        setW2BotPass(window.atob(localStorage.getItem(LOCAL_STORAGE_PREFIX + 'w2BotPass')||''))
        setLocalStoreIntialed(true)
    }
    }, [w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])


    useEffect(() => {
        let wapi = w1Api;
        let wbot = w1Bot;
        let wpass = w1BotPass;
        if(w2SearchParam){
            wapi = w2Api;
            wbot = w2Bot;
            wpass = w2BotPass;
        }

        if(!localStoreIntialed || !wapi || !wbot || !wpass
            || '' === `${wapi}` || '' === `${wbot}` || '' === `${wpass}`) 
        {
            return;
        }
        const W2W_API = process.env.REACT_APP_KDANNI_W2W_API_URL;

        const getWikiNamespaces = async () => {
            fetch(W2W_API,
                {
                    method: 'POST',                    
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify({
                        wiki: {
                            api: wapi, 
                            bot: wbot,
                            pass: wpass
                        },
                        queryData: {
                            action: 'parse',
                            pageid: pageid,
                            prop: 'revid|wikitext',
                            format: 'json'
                        }
                    })
                }
            )
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData);                
                const {success, wikiResponse} = responseData;
                // console.log(success, wikiResponse);                
                if(success && wikiResponse?.parse){
                    setW1PageData(wikiResponse.parse);
                    setW2PageName(wikiResponse.parse.title);
                    if(wikiResponse.parse?.wikitext && wikiResponse.parse.wikitext['*']) {
                        setW1PageWikiText(wikiResponse.parse.wikitext['*'])
                    }
                } else {
                    console.error('No page data.', responseData);    
                }
            })
            .catch((e) => {
                console.error(e)
            })
        }
        getWikiNamespaces()
    }, [apiToken, pageid, w2SearchParam, w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])

    useEffect(() => {
        if(!w2DoSubmit || w1PageWikiText < 1 || w2PageName.length < 1) {
            return
        }
        setW2DoSubmit(false)

        let wapi = w2Api;
        let wbot = w2Bot;
        let wpass = w2BotPass;
        if(w2SearchParam){
            wapi = w1Api;
            wbot = w1Bot;
            wpass = w1BotPass;
        }

        if(!localStoreIntialed || !wapi || !wbot || !wpass
            || '' === `${wapi}` || '' === `${wbot}` || '' === `${wpass}`) 
        {
            return;
        }
        const W2W_API = process.env.REACT_APP_KDANNI_W2W_API_URL;

        const getTargetPage = async () => {
            fetch(W2W_API,
                {
                    method: 'POST',                    
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify({
                        wiki: {
                            api: wapi, 
                            bot: wbot,
                            pass: wpass
                        },
                        queryData: {
                            action: 'parse',
                            page: w2PageName,
                            prop: 'revid',
                            format: 'json'
                        }
                    })
                }
            )
            .then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData);                
                const {success, wikiResponse} = responseData;
                // console.log(success, wikiResponse);                
                if(success && wikiResponse?.parse?.revid){
                    setW2SubmitResult(`Page already exist: ${JSON.stringify(wikiResponse.parse)}`)
                } 
                else if(success && wikiResponse?.error?.code === 'missingtitle') {
                    // setW2SubmitResult(`Page doesn't exist: ${JSON.stringify(wikiResponse.error.code)}`)
                    setW2SubmitOkPageDoesntExist(true);
                }
                else {
                    setW2SubmitResult(`${JSON.stringify(wikiResponse)}`)
                }
            })
            .catch((e) => {
                setW2SubmitResult(`${JSON.stringify(e)}`)
                console.error(e)
            })
        }
        getTargetPage()         
    }, [w2DoSubmit, w1PageWikiText, w2PageName, apiToken, w2SearchParam, w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])


    useEffect(() => {
        if(!w2SubmitOkPageDoesntExist || w1PageWikiText < 1 || w2PageName.length < 1) {
            return
        }
        setW2SubmitOkPageDoesntExist(false)

        let wapi = w2Api;
        let wbot = w2Bot;
        let wpass = w2BotPass;
        if(w2SearchParam){
            wapi = w1Api;
            wbot = w1Bot;
            wpass = w1BotPass;
        }

        if(!localStoreIntialed || !wapi || !wbot || !wpass
            || '' === `${wapi}` || '' === `${wbot}` || '' === `${wpass}`) 
        {
            return;
        }
        const W2W_API = process.env.REACT_APP_KDANNI_W2W_API_URL;

        const editTargetPage = async () => {
            fetch(W2W_API,
                {
                    method: 'POST',                    
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify({
                        wiki: {
                            api: wapi, 
                            bot: wbot,
                            pass: wpass
                        },
                        formData: {
                            action: 'edit',
                            title: w2PageName,
                            text: w1PageWikiText,
                            format: 'json'
                        }
                    })            
                }
            )
            .then((response) => response.json())
            .then((responseData) => {
                if(responseData?.wikiResponse?.edit){
                    setW2SubmitResult(`Edit result: ${JSON.stringify(responseData?.wikiResponse?.edit)}`)
                } else {
                    setW2SubmitResult(`${JSON.stringify(responseData)}`)
                }
            })
            .catch((e) => {
                setW2SubmitResult(`${JSON.stringify(e)}`)
                console.error(e)
            })            
        }  
        editTargetPage()  
    }, [w2SubmitOkPageDoesntExist, w1PageWikiText, w2PageName, apiToken, w2SearchParam, w1Api, w1Bot, w1BotPass, w2Api, w2Bot, w2BotPass, localStoreIntialed])


    const submitPage = () => {
        setW2DoSubmit(true)
    } 

    const getSubmitResult = () => {
        if(!w2SubmitResult) {
            return (<></>)
        } else {
            let apiUrl = w2SearchParam?w1Api:w2Api;
            apiUrl = apiUrl.replace(/api.php$/, '');
            let parentPage = null;
            let match = (/(.+)(\/[^/]+)$/.exec(w2PageName)||[])
            if(match[1] && match[2]) {
                parentPage = match[1];
            }
            return(<>
                <p>{w2SubmitResult}</p>
                <p><a href={`${apiUrl}index.php?title=${w2PageName}`}>{w2PageName}</a></p>
                {parentPage && (<p><a href={`${apiUrl}index.php?title=${parentPage}`}>{parentPage}</a></p>)}
            </>)
        }
    }
    
    const getWikitext = () => {
        if(w1PageData?.wikitext) {
            return(<textarea id="theWikiText"
                rows={20} value={`${w1PageWikiText}`}
                onChange={(event) => {setW1PageWikiText(event.target.value);}}
            ></textarea>)
        } else {
            return(<textarea id="theWikiText" readOnly={true} >
            </textarea>)
        }
    }

    const getMetaData = () => {
        if(w1PageData?.pageid) {
            return(<ul id="thePageMetaDataList">
                    <li>Wiki: {w2SearchParam?'2':'1'}</li>
                    <li>pageid: {w1PageData.pageid}</li>
                    <li>revid: {w1PageData.revid}</li>
                </ul>)
        } else {
            return(<></>)
        }
    }

    const getPageLink = () => {
        if(w1PageData?.pageid) {
            let apiUrl = w2SearchParam?w2Api:w1Api;
            apiUrl = apiUrl.replace(/api.php$/, '');
            return(<p><a href={`${apiUrl}index.php?title=${w1PageData.title}`}>{w1PageData.title}</a></p>)
        } else {
            return(<></>)
        }
    }
    const getTargetPageLink = () => {
        if(w2PageName.length > 0) {
            let apiUrl = w2SearchParam?w1Api:w2Api;
            apiUrl = apiUrl.replace(/api.php$/, '');
            return(<p><label>TOBE page<a href={`${apiUrl}index.php?title=${w2PageName}`}>{w2PageName}</a></label></p>)
        } else {
            return(<></>)
        }
    }

    return (
        <>
            <div id="w2w-page">
            <h1>Wiki {w2SearchParam?'2':'1'} Page</h1>
            <section>
                <Link to={'/w2w/'}>Wiki 2 Wiki</Link> | <Link to={'/wiki1pagelist/' + (w2SearchParam?'?w2=w2':'')}>Wiki {w2SearchParam?'2':'1'} Page List</Link>
            </section>
            <section>
                <h2>Config info</h2>
                <p>Page ID: {pageid}</p>
                <p><label>
                    Wiki 1 API URL
                    <input value={w1Api} type="text" className="w2w-conf-input" readOnly={true}></input>
                </label></p>
                <p><label>
                    Wiki 1 Bot Name
                    <input value={w1Bot} type="text" className="w2w-conf-input"  readOnly={true}></input>
                </label></p>
                <p><label>
                    Wiki 2 API URL
                    <input value={w2Api} type="text" className="w2w-conf-input" readOnly={true}></input>
                </label></p>
                <p><label>
                    Wiki 2 Bot Name
                    <input value={w2Bot} type="text" className="w2w-conf-input"  readOnly={true}></input>
                </label></p>
            </section>
            <section>
                <button onClick={() => console.log(w2PageName,w1PageWikiText)}>Debug</button>
            </section>
            <section>
                <p><label>
                    Wiki {w2SearchParam?'1':'2'} Target Page Name
                    <input value={w2PageName} type="text" className="w2w-param-input"
                        onChange={(event) => {setW2PageName(event.target.value);}}
                    ></input>
                </label></p>
                {getTargetPageLink()}
            </section>
            <section id="thePageData">
                <h2 id="thePageTitle">{w1PageData.title||'Page'}</h2>
                
                {getPageLink()}

                {getMetaData()}
                
                {getWikitext()}
              
                {/* <pre>{JSON.stringify(w1PageData)}</pre> */}
            </section>
            <section>
                <button onClick={submitPage}>Submit Page for Wiki {w2SearchParam?'1':'2'}</button>
            </section>
            <section>{getSubmitResult()}</section>
            </div>
        </>
    )
}

export default Wiki1Page