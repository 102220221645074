import React from 'react'
import {ApiTokenProvider} from './context/ApiTokenContext.js'
import Header from './components/Header'
import AuthContentWrapper from './components/auth0/AuthContentWrapper'
import { Outlet } from "react-router-dom";

function App() {
  
  return (
    <>
      <ApiTokenProvider>
        <Header />
        <main>
          <AuthContentWrapper>
            <Outlet />
          </AuthContentWrapper>
        </main>
      </ApiTokenProvider>
    </>
  )
}

export default App
