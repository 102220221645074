import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import User from './routes/user'
import Dashboard from './routes/dashboard'
import Wiki2Wiki from './routes/wiki2wiki'
import Wiki1PageList from './routes/wiki1pagelist'
import Wiki1Page from './routes/wiki1page'
import ConfigPage from './routes/configpage'
import Nocontent from './routes/nocontent'
import { Auth0Provider } from '@auth0/auth0-react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={`${process.env.REACT_APP_KDANNI_API_AUDIENCE}`}
      scope={`${process.env.REACT_APP_KDANNI_API_SCOPE}`}
      useRefreshTokens={ true }
      cacheLocation="localstorage"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="configpage" element={<ConfigPage />} />
            <Route path="configpage/:id" element={<ConfigPage />} />
            <Route path="user" element={<User />} />
            <Route path="w2w" element={<Wiki2Wiki />} />
            <Route path="wiki1pagelist" element={<Wiki1PageList />} />
            <Route path="wiki1page/:pageid" element={<Wiki1Page />} />
            <Route path="*" element={<Nocontent />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
