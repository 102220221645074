import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from './auth0/LoginButton'
import LogoutButton from './auth0/LogoutButton'
import { Link } from "react-router-dom";

function Header() {
  const { isAuthenticated, isLoading } = useAuth0()

  return (
    <>
      <header>
        <Link className='homelink' to="/">
          <h1 className='headerH1'>dash.kdanni.hu</h1>
        </Link>
        <nav>
            {!isLoading && isAuthenticated &&
              (<><Link to="/configpage/rootpage?root=true">Config Pages</Link>
              <Link to="/w2w">Wiki Migráns</Link>
              <Link to="/user">User</Link></>)
            }
        </nav>
        <div className='authbuttons'>
          {!isLoading && !isAuthenticated && <LoginButton />}
          {!isLoading && isAuthenticated && <LogoutButton />}
        </div>
      </header>
    </>
  )
}

export default Header
