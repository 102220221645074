import React, { useEffect, useState } from 'react'
import { useApiToken } from '../../context/ApiTokenContext'
import { Link } from 'react-router-dom'

function ConfigPageDisplay(props) {
    const { id, rootpage, hideRawContent } = props;

    const { apiToken, setApiToken } = useApiToken()
    const [thePage, setThePage] = useState({})
    const [pageLinkTitles, setPageLinkTitles] = useState([])
    const [linkedPages, setLinkedPages] = useState([])
    const [linked404Pages, setLinked404Pages] = useState([])
    const [urls, setUrls] = useState([])
    const [newPageTitleToPost, setNewPageTitleToPost] = useState(null)
    const [pageCreated, setPageCreated] = useState(null)

    useEffect(() => {
        setPageLinkTitles([])
        setLinkedPages([])
        setLinked404Pages([])
        setUrls([])
    }, [id, rootpage])

    useEffect(() => {
        const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
        const CP_PATH = rootpage ? 'rootpage/' : `id/${id}/`
        const getConfigPages = async () => {

            fetch(`${CP_API}${CP_PATH}`,
                {
                    headers: {
                        'Authorization': `Bearer ${apiToken}`
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const ret = async () => {
                            let val = 'Error:' + response.status + '\n'
                            val += await response.text()
                            for (const header of response.headers.entries()) {
                                val += '\n\t' + JSON.stringify(header)
                            }
                            return { content: val }
                        }
                        return ret()
                    }
                })
                .then((responseData) => {
                    //console.log(responseData)
                    if (responseData.message) {
                        setThePage({ content: 'Error\n' + JSON.stringify(responseData) })
                        throw new Error(responseData.message)
                    }
                    setThePage(responseData)
                })
                .catch((e) => {
                    console.error('Get config page Error', e)
                    setApiToken('')
                })
            setPageLinkTitles([])
            setLinkedPages([])
            setLinked404Pages([])
            setUrls([])
        }

        if (!apiToken || apiToken.length < 20) {
            return
        }
        getConfigPages()
    }, [id, rootpage, apiToken, setApiToken])

    useEffect(() => {
        const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
        if (!pageLinkTitles || pageLinkTitles.length < 1) {
            setLinkedPages([])
            return
        }
        // console.log(pageLinkTitles)
        for (const pageTitle of pageLinkTitles) {
            const [zero, pageTitleMatch] = /^\[\[(.+)]]$/.exec(pageTitle) || ['', null]
            if (!zero || !pageTitleMatch) {
                continue
            }
            fetch(`${CP_API}title/${pageTitleMatch}/`,
                {
                    headers: {
                        'Authorization': `Bearer ${apiToken}`
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else if (response.status === 404) {
                        setLinked404Pages(
                            prev => {
                                return [...prev, pageTitleMatch]
                            }
                        )
                    } else {
                        throw new Error('No Page')
                    }
                })
                .then((responseData) => {
                    if (responseData.message) {
                        throw new Error(responseData.message)
                    }
                    if(Array.isArray(responseData) && responseData.length === 0){
                        setLinked404Pages(
                            prev => {
                                return [...prev, pageTitleMatch]
                            }
                        )
                    } else {
                        setLinkedPages(
                            prev => {
                                return [...prev, responseData]
                            }
                        )
                    }
                })
                .catch((e) => {
                    //NOP
                })
        }

    }, [pageLinkTitles, apiToken])

    useEffect(() => {
        if (!thePage?.content) {
            setPageLinkTitles([])
            return
        }
        // console.log(thePage.content)
        const match = thePage.content.match(/\[\[.+?]]/gm) || []
        // console.log('PageTitle matches', match)
        setPageLinkTitles([...match])

    }, [thePage?.content])

    useEffect(() => {
        if (!thePage?.content) {
            setUrls([])
            return
        }
        // console.log(thePage.content)
        const match = thePage.content.match(/https?:\/\/[^ \t\r\n]+/gm) || []
        // console.log('URL matches', match)
        setUrls([...match])

    }, [thePage?.content])


    useEffect(() => {
        if (!newPageTitleToPost || !newPageTitleToPost.length || newPageTitleToPost.length < 3) {
            return
        }
        const CP_API = process.env.REACT_APP_KDANNI_CONFIG_PAGE_API_URL
        fetch(`${CP_API}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiToken}`
                },
                body: JSON.stringify({ content: `# [[${thePage?.title}]]`, title: newPageTitleToPost })
            })
            .then((response) => {
                if (response.ok || response.status === 201) {
                    return response.json()
                } else {
                    throw new Error('Not created')
                }
            })
            .then((responseData) => {
                if (responseData.message) {
                    throw new Error(responseData.message)
                } else {
                    setPageCreated(responseData)
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [newPageTitleToPost, apiToken, thePage?.title])

    const create404Page = (pageTitle) => {
        // console.log('create404Page', pageTitle)
        setNewPageTitleToPost(pageTitle)
    }

    useEffect(() => {
        if (!pageCreated) {
            return
        }
        setNewPageTitleToPost(null)
        window.location.reload()
    }, [pageCreated])

    const pageLinksSection = () => {
        return (<section className="theConfigpageDisplayPageLinks"> <ul> {
            // [].concat.apply([], linkedPages).map( (item) => {
            linkedPages.flat(1).map((item, i) => {
                return (
                    <li key={i}>
                        <Link to={`/configpage/${item._id}`}>&nbsp;{item.title}&nbsp;</Link>
                    </li>
                )
            })}
        </ul>
        </section>)
    }
    const urlLinksSection = () => {
        return (<section className="theConfigpageDisplayUrlLinks"> <ul> {
            // [].concat.apply([], linkedPages).map( (item) => {
            urls.map((item, i) => {
                return (
                    <li key={i}>
                        <a href={item}>&nbsp;{item}&nbsp;</a>
                    </li>
                )
            })}
        </ul>
        </section>)
    }
    const page404Section = () => {
        return (<section className="theConfigpageDisplay404Buttons"> <ul> {
            // [].concat.apply([], linkedPages).map( (item) => {
            linked404Pages.map((item, i) => {
                return (
                    <li key={i}>
                        <button onClick={() => create404Page(item)}>&nbsp;{item}&nbsp;</button>
                    </li>
                )
            })}
        </ul>
        </section>)
    }

    const rawContent = () => {
        return (
            <>
                <h2 className="theConfigpageDisplayH2">
                    {rootpage
                        ? (<Link to={'/configpage/rootpage?edit=true'}>Root Page</Link>)
                        : (<Link to={`/configpage/${id}?edit=true`}>{thePage?.title}</Link>)
                    }
                </h2>
                {/* <h3>ID: {thePage?._id}</h3> */}
                <textarea className="theConfigpageDisplayTextarea" value={thePage?.content} readOnly={true} />
            </>
        )
    }

    return (
        <>
            {!hideRawContent && rawContent()}
            {pageLinkTitles && pageLinkTitles.length > 0 && pageLinksSection()}
            {linked404Pages && linked404Pages.length > 0 && page404Section()}
            {urls && urls.length > 0 && urlLinksSection()}
        </>
    )
}

export default ConfigPageDisplay